import React from "react"
import {
  useCourseStore,
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import NotationGroup from "../../../../../components/notation-group"
import Aufgabe02Notation from "../../../../../content/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/aufgabe-02-notation"
import NotationCorrectionSolution from "../../../../../components/notation-correction-solution"
import Score from "../../../../../components/score"
import FeebackHeaderProgress from "../../../../../components/feedback-header-progress"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "korrigieren",
}

const Page = () => {
  const { answerStatusForDict, getStatsForTaskWithDict } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  const comparator = ({ answer, solution, status }) => {
    if (solution) {
      if (!answer?.correction) {
        return status.MISSED
      }
      if (answer?.correction === solution) {
        return status.VALID_CHECKED
      }
      return status.INVALID
    } else {
      if (answer?.correction) {
        return status.INVALID
      }
      return status.VALID_UNCHECKED
    }
  }
  const answers = answerStatusForDict({
    ...taskProps,
    taskId: "korrigieren-aufgabe-02",
    comparator,
  })
  const statistics = getStatsForTaskWithDict({
    ...taskProps,
    taskId: "korrigieren-aufgabe-02",
    comparator,
  })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/reiseerlebnisse" />
      }
      progress={progress}
    >
      <Seo title="Hölderlin korrigieren" />

      <Stack>
        <FeebackHeaderProgress
          variant="solution"
          correct={statistics.correct}
          total={statistics.correct + statistics.missed}
        />
        <TaskSidebar
          main={
            <NotationGroup initialState={answers}>
              <Aufgabe02Notation notationType={NotationCorrectionSolution} />
            </NotationGroup>
          }
          sidebar={<Score statistics={statistics} />}
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
